import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const InstagramEmbed = () => {
  useEffect(() => {
    window.instgrm.Embeds.process()
  }, [])

  return (
    <section id='historias-de-sucesso' className='flex min-h-screen w-full items-center justify-center'>
      <div className='m-4 flex w-11/12 flex-col items-center justify-center gap-8 md:m-8 md:w-9/12'>
        <header className='text-center text-4xl md:text-justify md:text-5xl'>Histórias de sucesso</header>
        <ul className='grid grid-cols-1 items-baseline justify-center gap-4 md:grid-cols-3 md:gap-12'>
          <li>
            <blockquote
              class='instagram-media'
              data-instgrm-permalink="https://www.instagram.com/reel/C9LQ_t5pnBs/?utm_source=ig_embed&amp;utm_campaign=loading"
              data-instgrm-version='14'
            ></blockquote>
          </li>
          <li>
            <blockquote
              class='instagram-media'
              data-instgrm-permalink='https://www.instagram.com/reel/C9cpX-vNQtP/?utm_source=ig_embed&amp;utm_campaign=loading'
              data-instgrm-version='14'
            ></blockquote>
          </li>
          <li>
            <blockquote
              class='instagram-media'
              data-instgrm-permalink='https://www.instagram.com/reel/DDFA_TGygc4/?utm_source=ig_embed&amp;utm_campaign=loadingw'
              data-instgrm-version='14'
            ></blockquote>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default InstagramEmbed
